<template>
    <ConfirmDialog group="dialog" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4><strong>{{$t('ApplicationManagement.Details.User_Details')}}</strong></h4>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Name')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Age')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.age }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Gender')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.gender === 'm' ? 'Male' : 'Female' }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Email')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.email }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Phone')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{"+" + applicant.country_code }}{{"-" + applicant.mobile }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Country_applicants')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.country_name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Height')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.height }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Weight')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant.weight }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Hobby')}}</label>
                    <div class="p-col-12 p-md-10" style="display: flex; justify-content: flex-start; flex-wrap: wrap; width: 80%">
                        <p>{{ getUserHobbies }}</p>
                    </div>
                </div>
                
                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.SNS_Info')}}</label>
                    <div class="p-col-12 p-md-10">
                        <!-- <i class="pi pi-instagram"></i> -->
                        <!-- <p class="pi pi-instagram"></p> -->
                        <span
                            v-if="applicant.facebook_url !== '' && applicant.instagram_url !== '' && applicant.youtube_url !== ''"
                            id="flexicon">
                            <p><a :href="applicant.youtube_url" target="_blank"><img src="https://img.icons8.com/material-sharp/24/null/youtube-play.png" /></a></p>
                            <p><a :href="applicant.instagram_url" target="_blank"><img src="https://img.icons8.com/material/24/null/instagram-new--v1.png" /></a></p>
                            <p><a :href="applicant.facebook_url" target="_blank"><img src="https://img.icons8.com/material-sharp/24/null/facebook-new.png" /></a></p>
                        </span>
                        <span
                            v-else-if="applicant.facebook_url !== '' && applicant.instagram_url !== '' && applicant.youtube_url === ''"
                            id="flexicon">
                            <!-- <p><img src="https://img.icons8.com/material-sharp/24/null/youtube-play.png"/></p> -->
                            <p><a :href="applicant.instagram_url" target="_blank"><img src="https://img.icons8.com/material/24/null/instagram-new--v1.png" /></a></p>
                            <p><a :href="applicant.facebook_url" target="_blank"><img src="https://img.icons8.com/material-sharp/24/null/facebook-new.png" /></a></p>
                        </span>
                        <span
                            v-else-if="applicant.facebook_url !== '' && applicant.instagram_url === '' && applicant.youtube_url === ''"
                            id="flexicon">
                            <!-- <p><img src="https://img.icons8.com/material-sharp/24/null/youtube-play.png"/></p> -->
                            <!-- <p><img src="https://img.icons8.com/material/24/null/instagram-new--v1.png"/></p> -->
                            <!-- <p><a :href="applicant.instagram_url" target="_blank"><img src="https://img.icons8.com/material/24/null/instagram-new--v1.png" /></a></p> -->
                            <p><a :href="applicant.facebook_url" target="_blank"><img src="https://img.icons8.com/material-sharp/24/null/facebook-new.png" /></a></p>
                        </span>
                        <span
                            v-else-if="applicant.facebook_url === '' && applicant.instagram_url === '' && applicant.youtube_url === ''"
                            id="flexicon">
                            <!-- <p><img src="https://img.icons8.com/material-sharp/24/null/youtube-play.png"/></p> -->
                            <!-- <p><img src="https://img.icons8.com/material/24/null/instagram-new--v1.png"/></p> -->
                            <p>N/A</p>
                        </span>

                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Specialty')}}</label>
                    <div class="p-col-12 p-md-10" style="display: flex; justify-content: flex-start; flex-wrap: wrap; width: 80%">
                        <p>{{ getUserSpecials }}</p>
                        <!-- <div v-for="(specializations, Spec) in specializations" :key="Spec" style="margin:5px;">
                            <p>{{ specializations.specialization_title }}
                                {{ Spec !== specializations.length - 2 ? ',' : '' }}
                            </p>
                        </div> -->
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="title" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Photos')}}</label>
                    <div class="p-col-12 p-md-10" style="display: flex; justify-content: flex-start; flex-wrap: wrap; width: 80%">
                        <div v-for="(images, img) in images" :key="img" style="margin:5px;">
                            <div class="text-red" v-show="render4">{{ $t('validation.invalidFile') }}</div>
                            <!-- <img :src="'https://asnhist-bcknd.dvconsulting.org/asnhist-dev/api/v1/' + image_url" alt="이미지를 사용할 수 없음" class="product-image" /> -->
                            <img :src="images.image_url" alt="이미지를 사용할 수 없음" class="product-image" />
                            <div>
                                <a href="javascript:;"><img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/UlEQVR4nO2ZTy8DQRjGfyF65OimSHCl4cBRceLrSHBx58Cl2rs4iKR8Bj4BZ/+vRIsTjcokb5M3m213Z0d325gnmWQ788w7zzN/tp234OHh4dHrGAcqwDPQAJptimmfjRFvTrjt4jSkvQzkXcWvAe8dBguWgxgxDy3i1YEVl5m3EW/KeYy4F5Yx68BYEgMVFeQOWAZyITxT9ym8GjDcIeaICDLcD2CoTbwicK/GP0piQO9TI74TzhT3GBgM4Zi6E8U7jYhZVNzHJAb0gQ2beY0l4EfxL4F1YFTKBnCl2g13MSJmLnCwraH3YRzsW+zrvS5pcOo8AOwA3x2EfwHbwo2DVA20MCmrcSOH25RrqZtISYN75z9C0xvAr0D/bqEZ4Al4AKb60cC26r/VjwZ2VX/znLqGpjeAX4Gm30JEH6Bp+b1+CxRiHOKCcE2fqV5YgU3Fe1UmwgwUhNOq3+wFA5MBUa+SeQgamAvhTfSCgbCZNc9V9bka0q63W+YGkJzQS4zb2Buw0CUNzp2jTNiIz+w12s6EET+fkgbnzkETScRn/kU2K+/725g50zD87ytlwyKx1Q3kXBNbTxapxW5gRY1vbnXWKKsA9xIwjZXIAauB5G4pSaC8yiRnWWpJ0+vIrNczFl/EEWOyhI8RfzH9VWnIWCWXmffw8PAgFfwCD+rjsELAeM4AAAAASUVORK5CYII="
                                        class="download-icon" @click="ImgDownload(images.download_path)" /></a>
                            </div>
                        </div>
                        <div class="downloadAll" v-if="images.length !='0'">
                            <a href="javascript:;"><img src="https://img.icons8.com/ios/28/null/download--v1.png"  class="AllDwn" @click="downloadMultipleImages"/></a><a class="dwntxt" @click="downloadMultipleImages">모두 다운로드</a>
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Vote_Count')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ voteCount.vote_count }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Apply_Date_&_Time')}}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{
                            applicant.registration_date === null ? 'N/A' : startdateformat(applicant.registration_date)
                        }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('ApplicationManagement.Details.Selection_Status')}}</label>
                    <div class="p-col-12 p-md-10">
                        <span v-if="applicant.is_shortlisted == 1">
                            <p>승인됨</p>
                        </span>
                        <span v-else-if="applicant.is_shortlisted == 2">
                            <p>보류 중</p>
                        </span>
                        <span v-else>
                            <p>거부하다</p>
                        </span>
                    </div>
                </div>
                <div class="p-d-flex p-jc-end p-ai-center">
                    <div>
                        <Button :label="$t('button.back')" icon="pi pi-replay" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"
                            @click="$router.go(-1)"></Button>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import axios from 'axios';
import VotemanagementService from '../../../service/API/SuperAdmin/VotemanagementService.js';
// import {useRouter} from 'vue-router'
import moment from 'moment';
export default {
    data() {
        return {
            dropdownGenderValues: [
                { name: 'Male', value: 'm' },
                { name: 'Female', value: 'f' },
            ],
            dropdownGenderValue: null,
            dropdownAppStatusValues: [
                { name: 'Active', value: 'active' },
                { name: 'Inactive', value: 'inactive' },
            ],
            dropdownAppStatusValue: null,
            title: '',
            country_id: '',
            max_user_apply: '',
            image: '',
            description: '',
            release_date: '',
            expire_date: '',
            voting_start_date: '',
            voting_end_date: '',
            status: 'active',
            selectedApplicantCount: '',
            applicantCount: '',

            file: '',
            files: '',
            fileName: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            name: '',
            email: '',
            age_from: '',
            age_to: '',
            gender: '',
            height_from: '',
            height_to: '',
            weight_from: '',
            weight_to: '',
            from_date: '',
            to_date: '',
            applicationStatus: '',
            registrationDate: '',
            application_id: '',
            dateVotingRange: '',
            Applicants: [],
            id: '',
            applicant: [
                {
                    user_id: '',
                    application_id: '',
                    registration_date: '',
                    name: '',
                    mobile: '',
                    age: '',
                    country_name: '',
                    gender: '',
                    email: '',
                    height: '',
                    youtube_url: '',
                    facebook_url: '',
                    instagram_url: '',
                    weight: '',
                    is_shortlisted: '',
                    country_code:''
                }
            ],
            hobbies: [
                {
                    title: ''
                }
            ],
            specializations: [
                {
                    specialization_title: ''
                }

            ],
            images: [],
            voteCount: [
                {
                    vote_count: ''
                }
            ]
            // type:''

        };
    },
    created() {
        this.votemanagementService = new VotemanagementService();
    },
    computed: {
        getUserHobbies() {
            return this.hobbies.map(value => value.title).join(', ');
            // console.log(this.hobbies)
        },
        getUserSpecials() {
            return this.specializations.map(value => value.specialization_title).join(', ');
            // console.log(this.hobbies)
        }
    },

    mounted() {
        // this.getApplicantList();
        this.votemanagementService.viewApplicants(this.$route.params.id).then((res) => {
            this.applicant.name = res.data.data.applicant[0].name;
            this.applicant.age = res.data.data.applicant[0].age;
            this.applicant.gender = res.data.data.applicant[0].gender;
            this.applicant.email = res.data.data.applicant[0].email;
            this.applicant.mobile = res.data.data.applicant[0].mobile;
            this.applicant.country_name = res.data.data.applicant[0].country_name;
            this.applicant.height = res.data.data.applicant[0].height;
            this.applicant.weight = res.data.data.applicant[0].weight;
            this.applicant.registration_date = res.data.data.applicant[0].registration_date;
            this.applicant.instagram_url = res.data.data.applicant[0].instagram_url;
            this.applicant.facebook_url = res.data.data.applicant[0].facebook_url;
            this.applicant.youtube_url = res.data.data.applicant[0].youtube_url;
            this.voteCount.vote_count = res.data.data.voteCount[0].vote_count;
            this.specializations = res.data.data.specializations;
            this.hobbies = res.data.data.hobbies;
            this.applicant.is_shortlisted = res.data.data.applicant[0].is_shortlisted;
            this.applicant.country_code = res.data.data.applicant[0].country_code;
            // this.hobbies.title = res.data.data.hobbies.title.toString().split(",");
            this.images = res.data.data.images;

            console.log(this.image_url)
        });
    },
    methods: {
        startdateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD h:m:s');
                return moment.utc(value).locale('ko').format('ll - LTS');
            }
        },
        Regdateformat(value) {
            if (value) {
                return moment(String(value)).format('YYYY/MM/DD ');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },



        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },
        ImgDownload(url) {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
                withCredentials: false,
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file.jpg');
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async downloadImage(images) {
            const response = await fetch(images.download_path);
            const blob = await response.blob();
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = images.download_path;
            a.setAttribute('download', 'images.jpg');
            a.click();
        },
        async downloadMultipleImages() {
            for (const img of this.images) {
                await this.downloadImage(img);
            }
        }

    }
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;
    /* margin-left: 25%; */
}

span#flexicon {
    display: flex;
    align-items: center;
}
span#flexicon p{
    margin: 0;
}

.itemlist+.itemlist:before {
    content: ", ";
}
.downloadAll {
    display: flex;
    margin-top: 36px;
    margin-left: 12px;
}
a.dwntxt {
    margin-top: 10px;
    display: flex;
    color: black;
    cursor: pointer;
}
</style>