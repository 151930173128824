import axios from 'axios';
export default class VotemanagementService {

    async getvotemanagementList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/application', data);
    }

    async getCountrydropdown() {
        return await axios
            .get(`asnhist-dev/api/v1/admin/country`, {

            })
            .then((res) => res);
    }

    async viewVoteManagement(ids) {
        return await axios.post(`asnhist-dev/api/v1/admin/application/id`, { id: ids }).then((res) => res);
    }
    async getShortListVoteApplicatList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/application/short_listed_applicants_list', data);
    }
    async getParticularVoteApplicatList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/application/applicants_list', data);
    }
    async viewApplicants(ids) {
        return await axios.post(`asnhist-dev/api/v1/admin/application/applicant_id`, { applicantId: ids }).then((res) => res);
    }
    async getWinnerList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/application/view_winners', data);
    }


}